<template>
  <div class="flex h-10 items-center justify-center bg-green-50 text-center text-[11px] uppercase text-charcoal-100/80 md:text-desktop-caption">
    <span v-if="props.notification != ''">
      {{ props.notification }}
    </span>

    <span v-else>
      {{ now.format("dddd, DD MMMM") }} {{ standartNotification }}
    </span>
  </div>
</template>

<script lang="ts" setup>
import dayjs from "dayjs";

const props = defineProps<{
  notification: string;
}>();

const now = dayjs();

const standartNotification = computed(() => {
  switch (true) {
    /* Sundays */
    case now.day() === 0 && now.hour() < 10:
      return " — Opening at 10am";
    case now.day() === 0 && now.hour() >= 10 && now.hour() <= 15:
      return " — Now Open — Closing at 4pm today";
    case now.day() === 0 && now.hour() >= 16:
      return " — Now Closed";

      /* Mondays */
    case now.day() === 1:
      return " — We are closed on Mondays";

      /* Tuesday to Saturday */
    case now.day() >= 2 && now.hour() < 10:
      return " — Opening at 10am";
    case now.day() >= 2 && now.hour() >= 10 && now.hour() <= 16:
      return " — Now Open — Closing at 5pm";
    case now.day() >= 2 && now.hour() >= 17:
      return " — Now Closed";

    default:
      return "";
  }
});
</script>
