<template>
  <div
    v-if="searchOpen"
    class="relative"
  >
    <input
      id="search"
      v-model="searchParams"
      type="search"
      autocomplete="off"
      placeholder="Search"
      class="block min-w-[300px] rounded-sm border-0 border-b-2 bg-transparent px-4 py-2 !outline-none !ring-0"
      :class="[
        shrink
          ? '!border-charcoal-100/50 !text-charcoal-100 placeholder:!text-charcoal-100/50 focus:!border-charcoal-100'
          : '',
        overlay
          ? 'border-white/50 text-white placeholder:text-white/50 focus:border-white'
          : '',
        route.name === 'index' || route.name === 'whats-on-uid'
          ? 'border-white/50 text-white placeholder:text-white/50 focus:border-white'
          : 'border-charcoal-100/50 text-charcoal-100 placeholder:text-charcoal-100/50 focus:border-charcoal-100',
      ]"
      @keyup.enter="toggleSearch"
    />
  </div>

  <button
    type="button"
    class="hidden xl:block"
    @click="toggleSearch"
  >
    <SearchIcon
      class="size-8"
      :class="[
        shrink ? '*:!fill-charcoal-100' : '',
        overlay ? '*:!fill-white' : '',
        route.name === 'index' || route.name === 'whats-on-uid' ? '*:fill-white' : '*:fill-charcoal-100',
      ]"
      aria-hidden="true"
    />
  </button>
</template>

<script lang="ts" setup>
import SearchIcon from "~/assets/svgs/search.svg";

const route = useRoute();
const router = useRouter();

const props = defineProps<{
  overlay?: boolean;
  shrink?: boolean;
}>();

const searchOpen = ref(false);
const searchParams = ref("");

const toggleSearch = () => {
  if (!searchOpen.value) {
    searchOpen.value = true;
  } else {
    searchOpen.value = false;
    if (searchParams.value != "") {
      router.push({ path: `/search/${searchParams.value}` });
    }
  }
};
</script>
