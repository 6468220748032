<template>
  <div>
    <NavBar />
    <div
      id="smooth-wrapper"
      class="overflow-x-hidden bg-rust-25 font-primary"
    >
      <div id="smooth-content">
        <div class="min-h-screen">
          <slot />
        </div>
        <NavFooter />
      </div>
    </div>
  </div>
</template>
