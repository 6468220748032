<template>
  <div
    class="z-30 w-full transition-[top] duration-500"
    :class="[
      fixed ? 'fixed bg-white !text-charcoal-100 shadow-xl' : 'absolute',
      hidden ? 'top-[-250px] opacity-0' : settings?.data.display_bar ? 'top-0' : '-top-10',
      route.name === 'index' || route.name === 'whats-on-uid' ? 'text-white' : 'text-charcoal-100',
    ]"
  >
    <UIAnnouncement
      v-if="!fixed"
      :notification="settings?.data.message || ''"
    />

    <nav
      class="mx-auto flex justify-between px-10"
      :class="fixed ? 'items-center py-4' : 'items-start py-6'"
      aria-label="Global Navigation"
    >
      <!-- Logo -->
      <NuxtLink to="/">
        <div
          class="select-none"
          :class="fixed ? 'text-logo-shrink' : 'text-logo md:text-tablet-logo lg:text-desktop-logo'"
        >
          LIGHTBOX<br />GALLERY
        </div>
      </NuxtLink>

      <!-- Menu -->
      <div class="hidden xl:block">
        <div
          v-for="(item, index) in navigation?.data.main"
          :key="item.label || index"
          class="link mx-5 inline-block"
        >
          <NuxtLink
            v-if="item.link.link_type != 'Any'"
            :to="prismic.asLink(item.link) ?? ''"
            :class="fixed ? 'text-large-copy' : 'text-desktop-large-copy'"
          >
            {{ item.label }}
          </NuxtLink>
        </div>
      </div>

      <!-- Actions -->
      <div class="relative z-30 flex gap-4">
        <!-- Donate Button -->
        <a
          href="/donations"
          class="hidden rounded-md border border-charcoal-100 bg-charcoal-100 px-3.5 py-1.5 text-center text-body-copy text-white transition-colors hover:border-charcoal-75 hover:bg-charcoal-75 md:block"
        >Donate</a>

        <!-- Search -->
        <SearchForm :shrink="fixed" />

        <!-- Hamburguer Icon -->
        <button
          type="button"
          @click="toggleMenu"
        >
          <MenuIcon
            class="size-8"
            :class="[
              fixed
                ? '*:!stroke-charcoal-100' : '',
              route.name === 'index' || route.name === 'whats-on-uid'
                ? '*:stroke-white' : '*:stroke-charcoal-100',
            ]"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  </div>

  <!-- Overlay Menu -->
  <NavOverlay
    :open="menuOpen"
    class="fixed z-40"
    @toggle-menu="toggleMenu"
  />
</template>

<script lang="ts" setup>
import MenuIcon from "~/assets/svgs/menu.svg";

const route = useRoute();
const prismic = usePrismic();

const { data: navigation } = useAsyncData("navigation", () =>
  prismic.client.getSingle("navigation"),
);
const { data: settings } = useAsyncData("settings", () =>
  prismic.client.getSingle("settings"),
);

const menuOpen = ref(false);
const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
};

/* Shrink NavBar on Scroll */
const fixed = ref(false);
const hidden = ref(false);
const startY = ref(0);

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

const handleScroll = () => {
  const scrollY = window.scrollY;

  if (scrollY <= 80) {
    fixed.value = false;
    hidden.value = false;
  } else {
    if (scrollY > startY.value) {
      fixed.value = false;
      hidden.value = true;
    } else {
      fixed.value = true;
      hidden.value = false;
    }
    startY.value = scrollY;
  }
};
</script>
